import {parents} from "./utilities";

export default class TrackingGa4 {
    self = this;

    /**
     * get tracking data with specific class click
     */
    getTrackingData() {
        self = this;
        document.body.addEventListener('click', (e) => {
            /*tracking menu voices*/
            if (e.target.closest('.menu-voice-tracking')) {
                e.preventDefault();
                let element = e.target.closest('.menu-voice-tracking');
                const objectString = element.getAttribute('data-object');
                const object = JSON.parse(objectString);
                self.sendTracking(object.level1, object.level2, object.level3, object.level4,  'click_menu', element);
            }
            /*tracking products click on LP and stories*/
            if (e.target.closest('.landing-page-product-tracking')) {
                e.preventDefault();
                let element = e.target.closest('.landing-page-product-tracking');
                self.sendTracking(element.dataset.trackingElementTypology, element.dataset.trackingPage, element.dataset.trackingProduct, '',   'landing_page_interaction', element);
            }
            /*tracking products click on LP and stories with component Product card*/
            if (e.target.closest('.landing-page-product-tracking-component')) {
                e.preventDefault();
                let element = e.target.closest('.landing-page-product-tracking-component');
                let elementA = element.querySelector('a');
                self.sendTracking(element.dataset.trackingElementTypology, element.dataset.trackingPage, element.dataset.trackingProduct, '',   'landing_page_interaction', elementA);
            }
            /*tracking navigation filters click and urls*/
            if (e.target.closest('.tracking-navigation-filter')) {
                e.preventDefault();
                let element = e.target.closest('.tracking-navigation-filter');
                self.sendTracking( window.location.pathname, element.dataset.trackingNavigationFilterCategory ,element.dataset.trackingNavigationFilterValue, '',   'navigation_filter_interaction', element);
            }
            /*tracking filters click and urls*/
            if (e.target.closest('.tracking-filter')) {
                let element = e.target.closest('.tracking-filter');
                self.sendTracking( window.location.pathname,element.dataset.trackingFilterCategory , element.dataset.trackingFilterValue,  '',   'filter_interaction', element, false);
            }
            /*tracking products click between different navigation (product list / collection) and urls*/
            if (e.target.closest('.tracking-navigation-product')) {
                e.preventDefault();
                let element = e.target.closest('.tracking-navigation-product');
                let elementA = element.querySelector('a');
                self.sendTracking( window.location.pathname,element.dataset.trackingNavigationProductSection , element.dataset.trackingNavigationProductName,  '',   'first_level_product_page_interaction', elementA);
            }
            /*tracking products slider homepage*/
            if (e.target.closest('.tracking-slider-product')) {
                e.preventDefault();
                let element = e.target.closest('.tracking-slider-product');
                let elementA = element.querySelector('a');
                self.sendTracking(element.dataset.trackingSliderGenderName ,  element.dataset.trackingSliderFamilyName, element.dataset.trackingSliderProductName, '' ,'slider_interaction',    elementA);
            }
            /*tracking related products*/
            if (e.target.closest('.tracking-related-product')) {
                e.preventDefault();
                let element = e.target.closest('.tracking-related-product');
                let elementA = element.querySelector('a');
                self.sendTracking(element.dataset.trackingRelatedProduct,'',  '', '',  'related_products', elementA);
            }
            /*tracking add to cart product*/
            if (e.target.closest('#add-to-cart-trigger')) {
                let element = e.target.closest('#add-to-cart-trigger');
                const objectString = element.getAttribute('data-object');
                const object = JSON.parse(objectString);
                self.sendEcommerceTracking(element.dataset.trackingAddToCardProductCurrency, element.dataset.trackingAddToCardProductPrice,[object.item],'add_to_cart', element ,false);
            }
            /*tracking add quantity on cart*/
            if (e.target.closest('.tracking-add-to-cart-plus')) {
                let element = e.target.closest('.tracking-add-to-cart-plus');
                const objectString = element.getAttribute('data-object');
                const object = JSON.parse(objectString);
                self.sendEcommerceTracking(element.dataset.trackingAddToCardProductCurrency, element.dataset.trackingAddToCardProductPrice,[object.item],'add_to_cart', element ,false);
            }
            /*tracking begin checkout*/
            if (e.target.closest('.tracking-begin-checkout')) {
                let element = e.target.closest('.tracking-begin-checkout');
                const objectString = element.getAttribute('data-object');
                const object = JSON.parse(objectString);
                self.sendEcommerceTracking(element.dataset.trackingBeginCheckoutCurrency, element.dataset.trackingBeginCheckoutPrice,object,'begin_checkout', element);
            }
            /*tracking technologies click*/
            if (e.target.closest('.tracking-technology')) {
                let element = e.target.closest('.tracking-technology');
                self.sendTracking(window.location.pathname, element.dataset.trackingTechnology, '', '', 'technology_interaction', element, false);
            }
        });
    }


    /**
     * send trackings to ga4
     * @type {NodeListOf<Element>}
     */
    sendTracking(element1, element2, element3, element4,  eventName, e, hasLink = true) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': eventName,
            'element1': element1,
            'element2': element2,
            'element3': element3,
            'element4': element4,
        });
        console.log(element1, element2, element3, element4, eventName);
        if(hasLink === true) {
            console.log(e.href);
            let anchor = document.createElement('a');
            anchor.href = e.href;
            anchor.target = e.target;
            anchor.click();
        }
    }


    sendEcommerceTracking(currency, price, items, eventName, e, hasLink = true) {
        e.preventDefault()
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                currency: currency,
                value: price,
                items: items
            }
        });

        if(hasLink) {
            let anchor = document.createElement('a');
            anchor.href = e.href;
            anchor.target = e.target;
            anchor.click();
        }
    }
}
