import {Loader} from "@googlemaps/js-api-loader";

export const skiTestsDetail = () => {
    // Snazzy map styles
    const styles = [
        {
            "featureType": "water",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#b5cbe4"
                }
            ]
        },
        {
            "featureType": "landscape",
            "stylers": [
                {
                    "color": "#efefef"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#83a5b0"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#bdcdd3"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e3eed3"
                }
            ]
        },
        {
            "featureType": "administrative",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": 33
                }
            ]
        },
        {
            "featureType": "road"
        },
        {
            "featureType": "poi.park",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {},
        {
            "featureType": "road",
            "stylers": [
                {
                    "lightness": 20
                }
            ]
        }
    ];

    const loader = new Loader({
        apiKey: "AIzaSyD-DL0j1P3oJFkJbD5vGaLP-8wnoYNWQlc",
    });

    const mapOptions = {
        zoom: 6,
        disableDefaultUI: true,
        zoomControl: true,
        center: {lat: 45.4408, lng: 12.3155},
        streetViewControl: false,
        mapTypeControl: false,
        styles
    };

    const targetMap = document.querySelector('#single-map')
    console.log(targetMap);
    const lat = parseFloat(targetMap.dataset.lat)
    const lng = parseFloat(targetMap.dataset.lng)

    // Promise
    loader
        .load()
        .then(() =>  initMapSingle(targetMap, lat, lng))
        .catch(e => console.error(e));

    // Init the map with a single marker in offcanvas modals
    function initMapSingle(mapElement, lat, lng) {
        if (!mapElement || !lat || !lng) return

        const centerPosition = {lat, lng}

        const map = new google.maps.Map(mapElement, {
            zoom: 10,
            center: centerPosition,
            streetViewControl: false,
            mapTypeControl: false,
            styles
        });

        // Custom marker
        const markerSvg = mapElement.dataset.marker;

        new google.maps.Marker({
            position: centerPosition,
            map,
            icon: {
                url: markerSvg,
                scaledSize: new google.maps.Size(25, 32)
            },
        });
    }

}


