import {matchMediaLGUp} from "./mediaQuery";
import {equation, startPageOnTop} from "./utilities";

export default (accordion) => {
    if (!document.body.classList.contains('js-start-page-on-top')) startPageOnTop()

    const items = accordion.querySelectorAll('.accordion-item')

    const vh = document.documentElement.clientHeight // 100vh
    const itemStartWidth = matchMediaLGUp.matches ? 60 : 100
    const itemMaxWidth = 100
    const itemStartOpacity = 0
    const itemMaxOpacity = 1

    // Values start and end animation in %
    const startAnimation = 10
    const endAnimation = 45

    // Items setup
    items.forEach(item => {
        item.style.width = itemStartWidth + '%'
        item.style.opacity = itemStartOpacity
        item.style.willChange = matchMediaLGUp.matches ? 'width opacity' : 'opacity'
        if (navigator.userAgent.indexOf('Firefox') === -1 && matchMediaLGUp.matches) {
            item.style.transition = 'width .3s linear, opacity .3s linear'
        }
    })

    const animateItem = item => {
        let heightTop = item.getBoundingClientRect().top
        const y = (heightTop * 100 / vh - 100) * -1

        // A(80, 0) B(100, 100)
        if (y > startAnimation && y <= endAnimation) {
            const x = equation(itemStartWidth, startAnimation, itemMaxWidth, endAnimation, y)
            const x2 = equation(itemStartOpacity, startAnimation, itemMaxOpacity, 35, y)
            item.style.width = x + '%'
            item.style.opacity = x2
        }

        if (y > endAnimation) {
            item.style.width = itemMaxWidth + '%'
            item.style.opacity = itemMaxOpacity
        }

        if (y < startAnimation) {
            item.style.width = itemStartWidth + '%'
            item.style.opacity = itemStartOpacity
        }
    }

    document.addEventListener('scroll', () => items.forEach(item => animateItem(item)))

    const observer = new MutationObserver(() => items.forEach(item => animateItem(item)));

    observer.observe(accordion, {
        subtree: true,
        childList: false,
        attributes: true,
        attributeFilter: ['class'],
        attributeOldValue: false,
        characterData: false,
        characterDataOldValue: false
    });
}
