import {matchMediaMDUp} from "./mediaQuery";
import {equation} from "./utilities";

export default (items) => {
    if (items.length) {
        const vh = document.documentElement.clientHeight // 100vh
        let itemStartWidth = items[0].clientWidth
        const itemMaxWidth = itemStartWidth * 100 / 35 // maxWidth in px
        let itemMaxHeight = itemMaxWidth * 9 / 16 // maxHeight in px

        // Values start and end animation in %
        const startAnimation = 30
        const endAnimation = 90

        // Items setup
        items.forEach(item => {
            item.style.width = 35 + '%'
            item.style.aspectRatio = 'auto'
            item.style.height = itemStartWidth + 'px'
            if (navigator.userAgent.indexOf('Firefox') === -1) {
                item.style.transition = 'width .1s ease-out'
            }
        })

        document.addEventListener('scroll', () => {
            if (!matchMediaMDUp.matches) {
                items.forEach(item => item.setAttribute('style', ''))
            } else {
                items.forEach(item => {
                    let heightTop = item.getBoundingClientRect().top
                    const y = (heightTop * 100 / vh - 100) * -1

                    // A(35,30) B(100,90)
                    if (y >= startAnimation && y <= endAnimation) {
                        const x = equation(35, startAnimation, 100, endAnimation, y)
                        if (x <= 100) item.style.width = x + '%'
                    }

                    // A(itemStartWidth, 40) B(itemMaxHeight, 90)
                    if (y >= startAnimation + 10 && y <= endAnimation) {
                        const height = equation(itemStartWidth, startAnimation + 10, itemMaxHeight, endAnimation, y)
                        if (height <= itemMaxHeight) item.style.height = height + 'px'
                    }

                    if (y > endAnimation) {
                        item.style.width = 100 + '%'
                        item.style.height = itemMaxHeight + 'px'
                    }

                    if (y < startAnimation) {
                        item.style.width = 35 + '%'
                        item.style.height = itemStartWidth + 'px'
                    }
                })
            }
        })
    }
}
